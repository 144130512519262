<template>
  <div class="container mx-auto p-3 md:p-8">
    <div class="flex flex-col items-center">
      <article class="w-full md:w-2/3 bg-[#1e1e1f] border-[#383838] rounded-xl text-white p-5 md:p-8">
        
        <!-- ✅ Centered Title & Timestamp -->
        <h1 class="text-3xl font-bold text-amber-200 text-center mb-2">
          {{ article.title }}
        </h1>
        <div class="text-xs text-slate-400 italic mb-4 text-center">
          {{ article.date }}
        </div>

        <!-- ✅ Centered Image -->
        <div v-if="article.image" class="flex justify-center">
          <img :src="article.image" alt="" class="w-full md:w-auto rounded-lg mb-4" />
        </div>

        <!-- ✅ Rendered Markdown Content -->
        <div class="markdown-body" v-html="content"></div>

      </article>
    </div>
  </div>
</template>

<script>
import MarkdownIt from "markdown-it";

export default {
  data() {
    return {
      article: {
        title: "Loading",
        date: "Loading",
        image: "",
        description: "Fetching pages...",
      },
      content: "",
    };
  },
  async mounted() {
    const slug = this.$route.params.slug;
    await this.loadArticle(slug);
  },
  methods: {
    async loadArticle(slug) {
      try {
        const filePath = `/content/${slug}.md`; 
        const response = await fetch(filePath);
        if (!response.ok) throw new Error("Markdown file not found");

        const text = await response.text();

        // ✅ Extract front matter manually
        const frontMatterRegex = /^---\n([\s\S]+?)\n---/;
        const match = text.match(frontMatterRegex);

        let metadata = {};
        let markdownContent = text;

        if (match) {
          const yamlContent = match[1];
          markdownContent = text.replace(frontMatterRegex, "").trim();

          yamlContent.split("\n").forEach((line) => {
            const [key, ...value] = line.split(": ");
            if (key && value) {
              metadata[key.trim()] = value.join(": ").trim();
            }
          });
        }

        this.article = {
          title: metadata.title || "Untitled",
          date: metadata.date || "Unknown",
          image: metadata.image || "",
          description: metadata.desc || "Default blog description",
        };

        // ✅ Convert Markdown to HTML
        const md = new MarkdownIt({ html: true, linkify: true });  // Added linkify option
        this.content = md.render(markdownContent);

        // ✅ Update page title and Open Graph meta tags
        this.updateMetaTags();

      } catch (error) {
        console.error("Failed to load article:", error);
        this.content = `<h2>404 - Article Not Found</h2>`;
      }
    },
    updateMetaTags() {
      document.title = this.article.title; // ✅ Update title

      const metaTags = [
        { name: "description", content: this.article.description },
        { property: "og:title", content: this.article.title },
        { property: "og:description", content: this.article.description },
        { property: "og:image", content: this.article.image },
      ];

      metaTags.forEach(({ name, property, content }) => {
        let meta = document.querySelector(`meta[${name ? "name" : "property"}="${name || property}"]`);
        if (!meta) {
          meta = document.createElement("meta");
          if (name) meta.name = name;
          else meta.setAttribute("property", property);
          document.head.appendChild(meta);
        }
        meta.setAttribute("content", content);
      });
    }
  }
};
</script>

<style>
/* ✅ Global styles for Markdown content */
.markdown-body {
  max-width: 100%;
  line-height: 1.6;
  text-align: left;
  white-space: normal;
  font-size: 0.9rem; /* ✅ Smaller font size */
}

/* ✅ Fix Headers */
.markdown-body h1 {
  font-size: 1.6rem;
  font-weight: bold;
  margin-top: 0.8rem;
  margin-bottom: 0.4rem;
}

.markdown-body h2 {
  font-size: 1.4rem;
  font-weight: bold;
  margin-top: 0.7rem;
  margin-bottom: 0.3rem;
}

.markdown-body h3 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 0.6rem;
  margin-bottom: 0.3rem;
}

/* ✅ Fix Paragraphs */
.markdown-body p {
  margin-bottom: 0.8rem;
}

/* ✅ Fix Lists */
.markdown-body ul,
.markdown-body ol {
  margin-left: 1.2rem;
  margin-bottom: 0.8rem;
}

/* ✅ Fix Blockquotes */
.markdown-body blockquote {
  padding: 0.4rem 0.8rem;
  border-left: 3px solid #facc15;
  background-color: rgba(255, 255, 255, 0.05);
  font-style: italic;
  font-size: 0.85rem; /* ✅ Smaller font */
}

/* ✅ Fix Code Blocks */
.markdown-body pre {
  background-color: #282c34;
  padding: 0.8rem;
  border-radius: 4px;
  overflow-x: auto;
  font-size: 0.85rem; /* ✅ Smaller code text */
}

.markdown-body code {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0.15rem 0.4rem;
  border-radius: 3px;
  font-family: monospace;
  font-size: 0.85rem;
}
/* ✅ Style for hyperlinks */
.markdown-body a {
  color: #4f94d4; /* Change to your preferred color */
  text-decoration: underline;
}

.markdown-body a:hover {
  color: #facc15; /* Add hover effect color */
  text-decoration: underline;
}
</style>
